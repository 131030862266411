import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';

function App() { 
  const [next, setNext] = useState('/results/latest.json');
  const [results, setResults] = useState([]);
  
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(next);
      const data = await response.json();
      setResults(results.concat(data.Results));
      setNext(data.Next);
    }

    fetchData();
  }, [next]);



  return (
    <div className="container-xl">
      <div className="row mt-4">
        <div className="col-lg">
          <h1 className="display-1">Every Amplify site<sup>*</sup></h1>
          <h3><sup>*</sup> Well, the ones that return 200 OK for the <code>master</code> branch</h3>
        </div>
      </div>
      {chunk(results.filter(site => site.Key.length > 0), 3).map(row => {
        return <div className="row mt-3">
          {row.map(site => {
            return <div key={site.Url} className="col-lg">
              <a href={site.Url}>
                <img className="img-thumbnail" src={`https://amplify.ge.cx/${site.Key}`} />
              </a>
            </div>;
          })}
        </div>
      })}
    </div>
  );
}

export default App;

function chunk (arr, len) {

  var chunks = [],
      i = 0,
      n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
}
